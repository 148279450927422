import { isBrowser } from "../utils";

const initialState = {
  phoneNumber:
    isBrowser() && window.localStorage.getItem("phoneNumber")
      ? JSON.parse(window.localStorage.getItem("phoneNumber"))
      : "",
  shippingAddress: {
    firstName:
      isBrowser() && window.localStorage.getItem("shipping")
        ? JSON.parse(window.localStorage.getItem("shipping")).firstName
        : "",
    lastName:
      isBrowser() && window.localStorage.getItem("shipping")
        ? JSON.parse(window.localStorage.getItem("shipping")).lastName
        : "",
    address:
      isBrowser() && window.localStorage.getItem("shipping")
        ? JSON.parse(window.localStorage.getItem("shipping")).address
        : "",
    apartment:
      isBrowser() && window.localStorage.getItem("shipping")
        ? JSON.parse(window.localStorage.getItem("shipping")).apartment
        : "",
    city:
      isBrowser() && window.localStorage.getItem("shipping")
        ? JSON.parse(window.localStorage.getItem("shipping")).city
        : "",
    country:
      isBrowser() && window.localStorage.getItem("shipping")
        ? JSON.parse(window.localStorage.getItem("shipping")).country
        : "",
    postalCode:
      isBrowser() && window.localStorage.getItem("shipping")
        ? JSON.parse(window.localStorage.getItem("shipping")).postalCode
        : "",
  },
  deliveryOption: {
    deliveryType: "Standard Delivery",
    deliveryPrice: 500,
  },
  payment: "Cash on Delivery",
  differentBillingAddress: false,
  billingAddress: {
    firstName:
      isBrowser() && window.localStorage.getItem("billing")
        ? JSON.parse(window.localStorage.getItem("billing")).firstName
        : "",
    lastName:
      isBrowser() && window.localStorage.getItem("billing")
        ? JSON.parse(window.localStorage.getItem("billing")).lastName
        : "",
    address:
      isBrowser() && window.localStorage.getItem("billing")
        ? JSON.parse(window.localStorage.getItem("billing")).address
        : "",
    apartment:
      isBrowser() && window.localStorage.getItem("billing")
        ? JSON.parse(window.localStorage.getItem("billing")).apartment
        : "",
    city:
      isBrowser() && window.localStorage.getItem("billing")
        ? JSON.parse(window.localStorage.getItem("billing")).city
        : "",
    country:
      isBrowser() && window.localStorage.getItem("billing")
        ? JSON.parse(window.localStorage.getItem("billing")).country
        : "",
    postalCode:
      isBrowser() && window.localStorage.getItem("billing")
        ? JSON.parse(window.localStorage.getItem("billing")).postalCode
        : "",
  },
};

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case `SET_PHONE_NUMBER`:
      return {
        ...state,
        phoneNumber: action.data,
      };
    case `SET_SHIPPING`:
      return {
        ...state,
        shippingAddress: action.data,
      };
    case `SET_DELIVERY`:
      return {
        ...state,
        deliveryOption: action.data,
      };
    case `SET_PAYMENT`:
      return {
        ...state,
        payment: action.data,
      };
    case `SET_BILLING_TYPE`:
      return {
        ...state,
        differentBillingAddress: action.data,
      };
    case `SET_BILLING`:
      return {
        ...state,
        billingAddress: action.data,
      };
    default:
      return state;
  }
};

export default checkoutReducer;
