import { isBrowser } from "../utils";

const initialState = {
  cart:
    isBrowser() && window.localStorage.getItem("cart")
      ? JSON.parse(window.localStorage.getItem("cart"))
      : [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case `SET_CART`:
      return {
        ...state,
        cart: action.data,
      };
    case `CLEAR_CART`:
      return {
        ...state,
        cart: [],
      };
    default:
      return state;
  }
};

export default cartReducer;
