const initialState = [];

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case `SET_ORDERS`:
      return [...action.data];
    default:
      return state;
  }
};

export default ordersReducer;
