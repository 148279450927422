import { isBrowser } from '../utils';

const initialState = {
	isLoggedIn:
		isBrowser() && window.localStorage.getItem('auth') ? true : false,
	isAdmin:
		isBrowser() && window.localStorage.getItem('auth')
			? JSON.parse(window.localStorage.getItem('auth')).user.admin
			: false,
	auth:
		isBrowser() && window.localStorage.getItem('auth')
			? JSON.parse(window.localStorage.getItem('auth'))
			: undefined,
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case `LOGIN`:
			return {
				...state,
				auth: action.data,
				isLoggedIn: true,
				isAdmin: action.data.user.isAdmin,
			};
		case `USER_LOGOUT`:
			return {
				...state,
				isLoggedIn: false,
				isAdmin: false,
				auth: undefined,
			};
		default:
			return state;
	}
};

export default authReducer;
