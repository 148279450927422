// React Confirm Alert
import "react-confirm-alert/src/react-confirm-alert.css";
// Lightbox
import "react-image-lightbox/style.css";
// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
// React Multi Carousel
import "react-multi-carousel/lib/styles.css";
// Local Styles
import "./src/styles/global.scss";

// Set up Redux
import wrapWithProvider from "./wrap-with-provider";
export const wrapRootElement = wrapWithProvider;

// Cloudinary upload widget
const addScript = (url) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};
export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://widget.cloudinary.com/v2.0/global/all.js");
  };
};
