import {
  createStore as reduxCreateStore,
  applyMiddleware,
  compose,
  combineReducers,
} from "redux";
import thunk from "redux-thunk";
import cartReducer from "./reducers/cart";
import authReducer from "./reducers/auth";
import productsReducer from "./reducers/products";
import checkoutReducer from "./reducers/checkout";
import ordersReducer from "./reducers/orders";

const composeEnhancers =
  (typeof window === "object" &&
    process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const appReducer = combineReducers({
  cart: cartReducer,
  auth: authReducer,
  products: productsReducer,
  checkout: checkoutReducer,
  orders: ordersReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const createStore = () =>
  reduxCreateStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default createStore;
