const initialState = {
  products: [],
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `SET_PRODUCTS`:
      return {
        ...state,
        products: action.data,
      };
    case `EDIT_PRICE`:
      return {
        ...state,
        products: [
          ...state.products.map((product) =>
            product.productID === action.data.productID
              ? { ...product, price: action.data.price }
              : product
          ),
        ],
      };
    case `EDIT_STOCK`:
      return {
        ...state,
        products: [
          ...state.products.map((product) =>
            product.productID === action.data.productID
              ? { ...product, stock: action.data.stock }
              : product
          ),
        ],
      };
    default:
      return state;
  }
};

export default productsReducer;
